var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.dialogAddInvestment = true}}},[_vm._v("Invertir")]),_c('DialogBox',{attrs:{"model":_vm.dialogAddInvestment,"max_width":'1000',"useRenderWatcher":true}},[_c('template',{slot:"toolbar"},[_c('span',[_vm._v("Añadir una inversión")])]),_c('template',{slot:"content"},[_c('v-card',{staticClass:"mb-5"},[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"black","dense":"","flat":""}},[_vm._v("Cantidades del proyecto")]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Moneda")]),_c('th',[_vm._v("Restante")]),_c('th',[_vm._v("Solicitado")])])]),_c('tbody',[(_vm.newInvest.investor)?_c('tr',[_c('td',[_vm._v(_vm._s(_vm.favoriteCurrency))]),_c('td',[_vm._v(_vm._s(_vm.maxAmountToInvestInFavoriteCurrency))]),_c('td',[_vm._v(_vm._s(_vm.amountRequestedInFavoriteCurrency))])]):_vm._e(),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.projectBag.currency))]),_c('td',[_vm._v(" "+_vm._s(_vm.newInvest.investor ? _vm.maxAmountToInvestInProjectBagCurrency : _vm.currency(_vm.projectBag.amount_invested_remaining, { fromCents: true }))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.newInvest.investor ? _vm.amountRequestedInProjectBagCurrency : _vm.currency(_vm.projectBag.amount, { fromCents: true }))+" ")])])])]},proxy:true}])})],1),_c('v-form',{ref:"formInvestProjectBag",model:{value:(_vm.formInvestProjectBagValid),callback:function ($$v) {_vm.formInvestProjectBagValid=$$v},expression:"formInvestProjectBagValid"}},[_c('InvestorAutocomplete',{attrs:{"editable":true},on:{"selected":function($event){return _vm.handleSelectInvestor($event)}}}),(
            _vm.newInvest.investor &&
            _vm.newInvest.investor.type === 'RETAIL' &&
            !_vm.newInvest.investor.completed_steps.includes('REGISTER_COMPLETED')
          )?_c('v-alert',{attrs:{"type":"warning","color":"warning"}},[_vm._v(" Este inversor no puede invertir hasta que complete su perfil ")]):_vm._e(),(
            _vm.newInvest.investor && (_vm.newInvest.investor.type === 'RETAIL' || (_vm.newInvest.investor.type === 'FUND' && _vm.partnerExchangeRate))
          )?_c('div',[_c('p',[_vm._v(" La suma de las carteras en "+_vm._s(_vm.projectBag.currency)+" es: "),_c('b',[_vm._v(_vm._s(_vm.funds.available_funds))])]),_c('v-text-field',{staticClass:"amount_field mt-1 mb-n5",attrs:{"type":"number","label":"Cantidad","outlined":"","suffix":_vm.suffix(),"rules":[
              (v) => !!v || 'Campo obligatorio',
              (v) => v > 0 || 'Introduce un valor positivo',
              _vm.insufficientFundsRule,
              _vm.maxAmountToInvestRule
            ]},model:{value:(_vm.newInvest.amount),callback:function ($$v) {_vm.$set(_vm.newInvest, "amount", _vm._n($$v))},expression:"newInvest.amount"}},[_c('template',{slot:"append"},[_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":""},on:{"click":function($event){_vm.newInvest.amount = _vm.maxAmountToInvestInProjectBagCurrency.value}}},[_vm._v("max.")])],1)],2)],1):(_vm.newInvest.investor && _vm.newInvest.investor.type === 'FUND' && !_vm.partnerExchangeRate)?_c('v-alert',{attrs:{"type":"warning","color":"warning"}},[_vm._v(" Este inversor fondo no tiene tipo de cambio asignada para el partner al que pertenece el proyecto ")]):_vm._e()],1)],1),_c('template',{slot:"actions"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeDialog('formInvestProjectBag')}}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"text":"","disabled":!_vm.formInvestProjectBagValid},on:{"click":function($event){return _vm.invest()}}},[_vm._v("invertir")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }