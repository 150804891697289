<template>
  <div>
    <v-btn small @click="dialogAddInvestment = true">Invertir</v-btn>

    <DialogBox :model="dialogAddInvestment" :max_width="'1000'" :useRenderWatcher="true">
      <template slot="toolbar">
        <span>Añadir una inversión</span>
      </template>
      <template slot="content">
        <v-card class="mb-5">
          <v-toolbar color="black" dense class="white--text" flat>Cantidades del proyecto</v-toolbar>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Moneda</th>
                  <th>Restante</th>
                  <th>Solicitado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="newInvest.investor">
                  <td>{{ favoriteCurrency }}</td>
                  <td>{{ maxAmountToInvestInFavoriteCurrency }}</td>
                  <td>{{ amountRequestedInFavoriteCurrency }}</td>
                </tr>
                <tr>
                  <td>{{ projectBag.currency }}</td>
                  <td>
                    {{
                      newInvest.investor
                        ? maxAmountToInvestInProjectBagCurrency
                        : currency(projectBag.amount_invested_remaining, { fromCents: true })
                    }}
                  </td>
                  <td>
                    {{ newInvest.investor ? amountRequestedInProjectBagCurrency : currency(projectBag.amount, { fromCents: true }) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-form ref="formInvestProjectBag" v-model="formInvestProjectBagValid">
          <InvestorAutocomplete :editable="true" @selected="handleSelectInvestor($event)" />

          <v-alert
            v-if="
              newInvest.investor &&
              newInvest.investor.type === 'RETAIL' &&
              !newInvest.investor.completed_steps.includes('REGISTER_COMPLETED')
            "
            type="warning"
            color="warning"
          >
            Este inversor no puede invertir hasta que complete su perfil
          </v-alert>
          <div
            v-if="
              newInvest.investor && (newInvest.investor.type === 'RETAIL' || (newInvest.investor.type === 'FUND' && partnerExchangeRate))
            "
          >
            <p>
              La suma de las carteras en {{ projectBag.currency }} es: <b>{{ funds.available_funds }}</b>
            </p>
            <v-text-field
              v-model.number="newInvest.amount"
              type="number"
              label="Cantidad"
              outlined
              :suffix="suffix()"
              class="amount_field mt-1 mb-n5"
              :rules="[
                (v) => !!v || 'Campo obligatorio',
                (v) => v > 0 || 'Introduce un valor positivo',
                insufficientFundsRule,
                maxAmountToInvestRule
              ]"
            >
              <template slot="append">
                <v-btn x-small class="ml-2" @click="newInvest.amount = maxAmountToInvestInProjectBagCurrency.value">max.</v-btn>
              </template>
            </v-text-field>
          </div>
          <v-alert
            v-else-if="newInvest.investor && newInvest.investor.type === 'FUND' && !partnerExchangeRate"
            type="warning"
            color="warning"
          >
            Este inversor fondo no tiene tipo de cambio asignada para el partner al que pertenece el proyecto
          </v-alert>
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('formInvestProjectBag')">Cerrar</v-btn>
        <v-btn text :disabled="!formInvestProjectBagValid" @click="invest()">invertir</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CommonMixin from "@/mixins/CommonMixin";
import _ from "lodash";
import DialogBox from "@/components/elements/DialogBox";
import InvestorAutocomplete from "@/components/elements/investor/InvestorAutocomplete";
import * as currency from "currency.js";

export default {
  components: {
    DialogBox,
    InvestorAutocomplete
  },
  mixins: [CommonMixin],
  props: {
    projectBag: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogAddInvestment: false,

      // Form invest project bag
      formInvestProjectBagValid: false,
      newInvest: {
        amount: 0,
        investor: null
      },

      // Main wallets of investor selected to invest
      wallets: [],

      // Funds available, withheld, etc...
      funds: {},
      partnerExchangeRate: null
    };
  },
  computed: {
    ...mapState({
      currencies: (state) => state.currencies.currencies
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    },

    favoriteCurrency() {
      return this.newInvest.investor?.favorite_currency;
    },

    walletAmountInFavoriteCurrency() {
      const available_funds = currency(this.wallets.find((e) => e.currency === this.favoriteCurrency)?.available_funds ?? 0, {
        fromCents: true
      });
      return available_funds;
    },

    walletAmountInProjectBagCurrency() {
      const available_funds = currency(this.wallets.find((e) => e.currency === this.projectBag.currency)?.available_funds ?? 0);
      return available_funds;
    },

    maxAmountToInvestInFavoriteCurrency() {
      const amount_invested_remaining = currency(this.projectBag.amount_invested_remaining, { fromCents: true });
      let amount = 0;

      if (this.newInvest.investor.type === "FUND") {
        amount = currency(amount_invested_remaining.value * (1 / this.partnerExchangeRate));
      } else {
        amount = this.convertCurrency(currency(amount_invested_remaining), this.projectBag.currency, this.favoriteCurrency);
      }

      return amount;
    },

    maxAmountToInvestInProjectBagCurrency() {
      return currency(this.projectBag.amount_invested_remaining, { fromCents: true });
    },

    amountRequestedInFavoriteCurrency() {
      const amount_requested = currency(this.projectBag.amount, { fromCents: true });
      let amount = 0;

      if (this.newInvest.investor.type === "FUND") {
        amount = currency(amount_requested.value * (1 / this.partnerExchangeRate));
      } else {
        amount = this.convertCurrency(currency(amount_requested), this.projectBag.currency, this.favoriteCurrency);
      }

      return amount;
    },

    amountRequestedInProjectBagCurrency() {
      return currency(this.projectBag.amount, { fromCents: true });
    },

    // Control if there are enough funds to invest
    insufficientFundsRule() {
      return (v) => v <= this.funds.available_funds || "Saldo insuficiente";
    },

    // Control max amount available
    maxAmountToInvestRule() {
      return (v) => v <= this.maxAmountToInvestInProjectBagCurrency || "Máximo de inversión superado";
    }
  },
  async created() {
    await this.$store.dispatch("investments/getInvestmentsSettings");
    await this.$store.dispatch("currencies/getCurrencies");
  },
  methods: {
    // Invest project
    async invest() {
      if (this.$refs.formInvestProjectBag.validate()) {
        await this.$store.dispatch("projectBags/investProjectBag", {
          id: this.projectBag.id,
          investor_id: this.newInvest.investor.id,
          amount:
            this.newInvest.amount > this.maxAmountToInvestInProjectBagCurrency.value
              ? this.maxAmountToInvestInProjectBagCurrency.intValue
              : this.convertToInt(this.newInvest.amount)
        });

        await this.$store.dispatch("projectBags/getProjectBag", { filter: { id: this.$route.params.id } });

        this.closeDialog("formInvestProjectBag");
      }
    },

    // Get the symbol of a currency
    currencySymbol(currency) {
      return this.currencies.find((e) => e.id === currency)?.symbol;
    },

    suffix() {
      let favoriteCurrencyAmount = 0;

      if (this.newInvest.investor.type === "FUND") {
        favoriteCurrencyAmount = currency(this.newInvest.amount * (1 / this.partnerExchangeRate));
      } else {
        favoriteCurrencyAmount = this.convertCurrency(currency(this.newInvest.amount), this.projectBag.currency, this.favoriteCurrency);
      }

      return `${this.currencySymbol(this.projectBag.currency)} / ${favoriteCurrencyAmount} ${this.currencySymbol(this.favoriteCurrency)}`;
    },

    async getWallets(investorId) {
      const wallets = await this.$store.dispatch("investors/getInvestorWallets", investorId);
      this.wallets = wallets.filter((e) => e.main_wallet === true);
    },

    async getInvestorSelected(investorId) {
      const investor = await this.$store.dispatch("investors/getInvestor", { filter: { id: investorId } });
      this.newInvest.investor = investor;
    },

    // Get the funds of the wallets in the currency of the project
    async getFundsInProjectBagCurrency() {
      let getTotalFundsData = {};

      if (this.newInvest.investor.type === "RETAIL") {
        getTotalFundsData = { investor_id: this.newInvest.investor.id, currency: this.projectBag.currency };
      } else {
        getTotalFundsData = {
          investor_id: this.newInvest.investor.id,
          currency: this.projectBag.currency,
          main_wallet_exchange_rate: this.partnerExchangeRate
        };
      }

      const funds = await this.$store.dispatch("investors/getTotalFunds", getTotalFundsData);

      for (const key of this._.keys(funds)) {
        funds[key] = currency(funds[key], { fromCents: true });
      }

      this.funds = funds;
    },

    // Get the exchange rate of a partner assigned to a fund investor
    getPartnerExchangeRate(partnerId) {
      this.partnerExchangeRate = this.newInvest.investor.exchange_rates[partnerId];
    },

    async handleSelectInvestor(investorId) {
      await this.getInvestorSelected(investorId);
      await this.getWallets(investorId);
      if (this.newInvest.investor.type === "FUND") this.getPartnerExchangeRate(this.projectBag.partner.id);
      if (this.newInvest.investor.type === "RETAIL" || (this.newInvest.investor.type === "FUND" && this.partnerExchangeRate)) {
        await this.getFundsInProjectBagCurrency();
      }
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(formRef) {
      this.$refs[formRef].resetValidation();
      Object.assign(this.$data, this.$options.data());
    }
  }
};
</script>
