<template>
  <div>
    <!-- Data table investments -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>
        <span>Inversiones</span>
        <v-spacer />
        <BtnAddInvestment
          v-if="investmentPermissions.invest.includes(user.role) && projectBagEditable.status === 'INVESTABLE'"
          :projectBag="projectBagEditable"
        />
      </v-toolbar>

      <DataTable
        :headers="tableInvestmentsHeaders"
        :items="projectBagEditable.investments ?? []"
        :sortDesc="false"
        :hideDefaultFooter="false"
        :disablePagination="false"
      >
        <template v-slot:[`item.investor`]="{ item }">
          <router-link :to="`/inversores/${item.investor.type.toLowerCase()}/${item.investor.id}`">{{ item.investor.name }}</router-link>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ currency(item.amount, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ formatDateL(item.date) }}
        </template>
        <template
          v-if="projectBagEditable.status === 'INVESTABLE' || projectBagEditable.status === 'FUNDED'"
          v-slot:[`item.actions`]="{ item }"
        >
          <v-icon
            v-if="investmentPermissions.delete.includes(user.role)"
            color="red"
            @click="(investmentSelected = item), (dialogDeleteInvestment = true)"
          >
            delete
          </v-icon>
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog delete investment -->
    <DialogBox :model="dialogDeleteInvestment" :color="'error'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de eliminar esta inversión?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogDeleteInvestment = false">Cerrar</v-btn>
        <v-btn text @click="(dialogDeleteInvestment = false), deleteInvestment()">eliminar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import BtnAddInvestment from "@/components/elements/project-bags/BtnAddInvestment";
import * as currency from "currency.js";

export default {
  components: {
    DataTable,
    DialogBox,
    BtnAddInvestment
  },
  mixins: [FormatDateMixin],
  props: {
    projectBagEditable: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // Investments datatable
      tableInvestmentsHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Inversor", value: "investor", sortable: false },
        { text: "Cantidad invertida", value: "amount", sortable: false },
        { text: "Fecha", value: "date" },
        { text: "", value: "actions", sortable: false }
      ],

      // Cards actions permissions
      investmentPermissions: {
        invest: ["SUPERADMIN", "ADMIN"],
        delete: ["SUPERADMIN", "ADMIN"]
      },

      // Investment selected to delete
      investmentSelected: null,

      // Dialogs
      dialogDeleteInvestment: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    currency() {
      return currency;
    }
  },
  methods: {
    async deleteInvestment() {
      await this.$store.dispatch("projectBags/deleteInvestment", {
        id: this.projectBagEditable.id,
        investment_id: this.investmentSelected.id
      });

      await this.$store.dispatch("projectBags/getProjectBag", { filter: { id: this.$route.params.id } });
    }
  }
};
</script>
