<template>
  <div>
    <v-expansion-panels multiple>
      <v-expansion-panel v-for="office in offices" :key="office.id" class="mb-3 secondary">
        <v-expansion-panel-header class="py-0">
          {{ office.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="white">
          <v-expansion-panels class="mt-2">
            <v-expansion-panel
              v-for="project in projectsTransfer.filter((e) => e.office_id === office.id)"
              :key="project.project_id"
              class="mb-3 secondary"
            >
              <v-expansion-panel-header class="py-0">
                {{ project.project_id }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="white">
                <ProjectSummaryTable :project="project" :isEditable="true" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProjectSummaryTable from "@/components/elements/credit-transfers/ProjectSummaryTable.vue";

export default {
  name: "ProjectListForTransfer",
  components: {
    ProjectSummaryTable
  },
  props: {
    projectsTransfer: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      offices: (state) => state.offices.offices
    })
  },
  async created() {
    const projectsTransferOfficesIds = [];
    for (const project of this.projectsTransfer) {
      if (!projectsTransferOfficesIds.some((e) => e === project.office_id)) {
        projectsTransferOfficesIds.push(project.office_id);
      }
    }
    if (projectsTransferOfficesIds.length > 0) {
      await this.$store.dispatch("offices/getOffices", { filter: { id: projectsTransferOfficesIds } });
    }
  }
};
</script>
